<template>
  <div class="tw-max-w-9xl tw-mx-auto sm:tw-px-8 lg:tw-px-16 tw-px-3">
    <div class="tw-pt-24 tw-text-center">
      <v-alert
          v-if="error"
          color="orange"
          outlined
          shaped
          type="info"
      >
        {{error.message}}
        {{error.errors}}

      </v-alert>


      <h1 class="tw-block tw-text-black tw-text-xl tw-leading-relaxed tw-font-semibold tw-mb-2">
        Set your password
      </h1>
      <p class="tw-text-lg tw-text-gray-n3 tw-mb-0">
        Welcome to <span class="tw-font-serif tw-text-red">Mango & Lola</span>
      </p>
    </div>

    <form class="tw-w-2/5 tw-mx-auto tw-text-left tw-mt-6 tw-pb-12" novalidate="true" @submit.prevent="onSubmit" method="POST">
      <ml-error-msg :msg="errors['error']" />

      <div class="tw-mt-6">
        <label for="password" class="tw-mb-0 tw-block tw-text-sm tw-font-semibold tw-text-gray-n3 tw-leading-relaxed">
          Password <span class="tw-text-red">*</span>
        </label>
        <div class="tw-mt-2 tw-relative tw-rounded-xl tw-border tw-border-gray-n1">
          <input v-model="form.password" id="password" name="password" :type="showPasswordValue ? 'text' : 'password'" autocomplete="current-password" required class="tw-appearance-none tw-bg-transparent tw-block tw-w-full tw-px-4 tw-py-3 tw-border-none tw-placeholder-gray-n1 focus:tw-outline-none focus:tw-ring-black focus:border-black sm:tw-text-sm">
          <a class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center" @click.prevent.stop="togglePassword" @mouseleave="hidePassword">
            <eye-icon class="tw-w-4 tw-h-4 tw-text-gray-n3" />
          </a>
        </div>
        <ml-error-msg :msg="errors['password']" />
      </div>

      <div class="tw-mt-6">
        <label for="confirm_password" class="tw-mb-0 tw-block tw-text-sm tw-font-semibold tw-text-gray-n3 tw-leading-relaxed">
          {{ $t('auth.confirm_password') }} <span class="tw-text-red">*</span>
        </label>
        <div class="tw-mt-2 tw-relative tw-rounded-xl tw-border tw-border-gray-n1">
          <input v-model="form.password_confirmation" id="confirm_password" name="confirm_password" :type="showPasswordValue ? 'text' : 'password'" autocomplete="confirm_password" required class="tw-appearance-none tw-bg-transparent tw-block tw-w-full tw-px-4 tw-py-3 tw-border-none tw-placeholder-gray-n1 focus:tw-outline-none focus:tw-ring-black focus:border-black sm:tw-text-sm">
          <a class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center" @click.prevent.stop="togglePassword" @mouseleave="hidePassword">
            <eye-icon class="tw-w-4 tw-h-4 tw-text-gray-n3" />
          </a>
        </div>
        <ml-error-msg :msg="errors['password_confirmation']" />
      </div>


      <div class="tw-mt-6 tw-text-xs tw-leading-relaxed tw-text-black">
        By submitting and sharing your information you agree to Mango & Lola’s <router-link target="_blank" class="tw-text-red" :to="{ name: 'terms.and.conditions'}">{{ $t('footer.terms_and_conditions') }}</router-link>
        and <router-link target="_blank" class="tw-text-red" :to="{ name: 'privacy.policy'}">{{ $t('footer.privacy_policy') }}</router-link>. And you agree to allow Mango & Lola to contact you regarding your
        bookings.
      </div>
      <spinner v-if="loading"></spinner>
      <button type="submit" :disabled="loading" class="tw-mt-6 tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-shadow-sm tw-text-white tw-bg-gray-n4 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
        {{ $t('auth.register') }}
      </button>
    </form>
  </div>
</template>

<script>
import Form from 'vform'
import i18n from '@/i18n'
//import MlLink from "@/components/layout/MlLink";
import { EyeIcon } from 'vue-feather-icons'
import MlErrorMsg from "@/components/layout/form/MlErrorMsg";


export default {
  middleware: 'guest',

  components: {
    //MlLink,
    EyeIcon,
    MlErrorMsg
  },

  metaInfo() {
    return {title: i18n.t('auth.activate_account')}
  },

  async created() {
    this.activationData = await this.$http.get(`/api/activate/${ this.$route.params.code }`);

    this.form.fill({
      name: this.activationData.data.name,
      email: this.activationData.data.email,
    })
  },

  data: () => ({
    activationData: {},
    showPassword: false,
    valid: true,
    loading: false,
    lazy: true,
    showPasswordValue: false,
    validPassword: "",
    form: new Form({
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      partner: '',
    }),
    mustVerifyEmail: false,
    errors: [],
    error: null,
    data: [],
    rules: {
      required: value => !!value || i18n.t('auth.required'),
      min: v => v.length >= 8 || i18n.t('auth.required_min'),
      passwordRules: v => !!v || i18n.t('auth.required_password'),
      confirmPasswordRules: v => !!v || i18n.t('auth.required_password_confirmation')
    },
    emailRules: [
      v => !!v || i18n.t('auth.email_required'),
      v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || i18n.t('auth.email_valid'),
    ],

  }),

  methods: {
    togglePassword() {
      this.showPasswordValue = !this.showPasswordValue;
    },
    hidePassword() {
      this.showPasswordValue = false;
    },
    checkForm: function () {
      this.errors = [];
      let label = '';
      this.loading = false;

      if (!this.form.password) {
        label = i18n.t('auth.required_password');
        this.errors["password"] = label;
        this.errors.push(label);
      } else {
        if (this.form.password.length < 8) {
          label = i18n.t('auth.password_min');
          this.errors["password"] = label;
          this.errors.push(label);
        }
        if (this.form.password !== this.form.password_confirmation) {
          label = i18n.t('auth.password_mismatch');
          this.errors["password_confirmation"] = label;
          this.errors.push(label);
        }
      }

      if (!this.errors.length) {
        this.valid = true;
        return true;
      }
      this.valid = false;
      return false;
    },
    onSubmit() {
      if (this.checkForm()) this.register();
    },
    async register() {

      if (!this.valid) return;
      /*
      await this.$refs.form.validate();
      if (!this.valid) return;
      */
      // Register the user.
      //const {data} = await this.form.post('/api/register')
      this.error = null;
      this.loading = true;

      try {

        await this.$http.get('sanctum/csrf-cookie');
        //await this.$http.post('api/register',this.form)
        await this.form.post('api/activate/' + this.$route.params.code)
            .then(({data}) => {
              this.data = data
            })
            .catch((error) => {
              this.loading = false;
              this.error = error.data;
              this.errors["error"] = "An error has occurred";
              // Error
              if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                console.log('Error response', error.response);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log('Error request.response', error.request.response);

              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error message', error.message);
              }
              //console.log('Error', error);
              return;
            });

        if (!this.error || this.data.registered) {
          // Log in the user.
          const {data: {token}} = await this.form.post('/api/login')

          // Save the token.
          await this.$store.dispatch('auth/saveToken', {token})
          await this.$store.dispatch('auth/fetchUser')

          // Update the user.
          //await this.$store.dispatch('auth/updateUser', { user: data })
          this.$toast.success(this.data.registered, "Success");
          // Redirect home.
          this.$router.push({name: 'welcome'})

        }
        this.loading = false;

      } catch (error) {
        this.error = error.data;
        this.errors["error"] = "An error has occurred";
      }
    }
  }

}
</script>
